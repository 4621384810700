<template>
  <KycContainer 
    @submit="onSubmit"
    :isMobile="isMobileDevice">
      <template slot="title">
        {{$t('kyc.modal.ssn.title')}}
      </template>

      <BasicInput
        :placeholder="$t('kyc.social_security_number')"
        @input="handleInputChange"
        v-model="form.ssn"
      />

      <KycInfoContainer class="mt-s24">
        <template slot="icon">
          <IconLocker class="text-text-active-2 fill-current w-s32 h-s32" />
        </template>
        <template slot="title">{{$t('kyc.modal.ssn.info_container_title')}}</template>
        <p>{{$t('kyc.modal.ssn.info_container_description')}}</p>
      </KycInfoContainer>

      <div class="flex flex-col w-full gap-s16" slot="actions">
        <ButtonV2
          :inactive="isLoading || !isValidSSN"
          :label="$t('kyc.continue')"
          testId="btn-continue"
          submit
          wide
        />
        <ButtonV2
          type="button"
          @onClick="onSkip"
          :label="$t('kyc.modal.ssn.button_add_later')"
          testId="btn-add-later"
          version="secondary"
          submit
          wide
        />
      </div>
  </KycContainer>
</template>

<script>
import {
  BasicInput,
} from '@/components/misc';

import { KycContainer, KycInfoContainer } from '@/components/kyc';
import { IconLocker } from '@/assets/icons';
import ButtonV2 from '@/stories/misc/ButtonV2';

import { applySSNMask, getOnlyNumbers } from '@/modules/user/utils/input';

export default {
  name: 'KycSSNForm',
  props: {
    isLoading: Boolean,
  },
  components: {
    ButtonV2,
    BasicInput,
    IconLocker,
    KycContainer,
    KycInfoContainer,
  },
  computed: {
    isValidSSN() {
      return /^(?!666|000|9\d{2})\d{3}(?!00)\d{2}(?!0{4})\d{4}$/.test(getOnlyNumbers(this.form.ssn));
    },
  },
  data() {
    return {
      form: {
        ssn: '',
      },
    };
  },
  methods: {
    handleInputChange(newValue) {
      const onlyNumbers = getOnlyNumbers(newValue).substring(0,9);

      if (onlyNumbers.length <= 9) {
        this.form.ssn = applySSNMask(onlyNumbers);
      }
    },
    onSkip(){
      this.$emit('skip');
    },
    onSubmit(){
      this.$emit('submit', getOnlyNumbers(this.form.ssn));
    }
  },
};
</script>
