<template>
  <KycSSNForm
    v-bind="formProps"
    @skip="onSkip"
    @submit="onSubmit"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import KycSSNForm from '@/modules/user/Modals/KycSSNForm/index.vue';
import adaptAttemptLevelUpForEmit from '@/modules/user/utils/adapt-attempt-level-up-for-emit.js';

export default {
  name: 'KycSSN',
  components: {
    KycSSNForm
  },
  data: () => ({
    isFormLoading: false,
  }),
  computed: {
    ...mapGetters('api', ['getCurrentUser', 'getCurrentUserInformation']),
    ...mapGetters('user', ['getKycLevelForAmplitude']),
    formProps(){
      return {
        isLoading: this.isFormLoading,
      };
    }
  },
  mounted(){
    this.setModalProps({ isClosable: false, });
    this.$store.dispatch('events/track', {
      event: 'KYC_GOVERNMENT_ID_VIEWED',
      variables: {
        ...this.getKycLevelForAmplitude,
      },
    });
  },
  methods: {
    ...mapActions('ui', ['setModalProps']),
    ...mapActions('user', ['postSSN']),
    onSkip(){
      this.showModal('KycStatus');
    },
    async onSubmit(ssn) {
      this.isFormLoading = true;
      try {
        const response = await this.postSSN(ssn);

        const emitVariables = adaptAttemptLevelUpForEmit(response);

        this.$store.dispatch('events/track', {
          event: 'KYC_GOVERNMENT_ID_SUBMITTED',
          variables: {
            ...emitVariables,
          },
        });

        this.showModal('KycStatus');
      } catch (e) {
        this.showError(e);
      } finally {
        this.isFormLoading = false;
      }
    },
  }
};
</script>
