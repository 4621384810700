export const handleInputDelete = (event) => {
  if (event.inputType === 'deleteContentBackward' || event.inputType === 'deleteContentForward') {
    return;
  }
};

export const getOnlyNumbers = (value) => {
  return String(value).replace(/\D+/g, '');
};

export const applySSNMask = (numbers) => {
  if (numbers.length < 4) {
    return numbers;
  }

  const areaCode = numbers.substring(0, 3);
  const centralOfficeCode = numbers.substring(3, 5);

  if (numbers.length < 6) {
    return `${areaCode} - ${centralOfficeCode}`;
  }

  const stationCode = numbers.substring(5, 9);

  return `${areaCode} - ${centralOfficeCode} - ${stationCode}`;
};
